<template lang="pug">
div(ref='displayWidget')
  div(v-if='displayType')
    CmsEditorTabs(
      :data='[ { name: "Results", clickHandler: () => toggleEditMode(false) }, { name: "No Results", clickHandler: () => toggleEditMode(true) }, ]'
    )

    AlgoliaResults
      template(#default='{ results, resultCount }')
        template(v-if='resultCount === 0 || ($storyblok.isEditing.value && editNoResults)')
          template(v-if='data.noResultWidgets')
            template(v-for='widget in data.noResultWidgets')
              slot(:data='widget')
        template(v-else)
          template(v-if='data.widgets')
            template(v-for='widget in data.widgets')
              slot(:data='widget')
          Carousel(v-if='displayMode === "CAROUSEL"' container-class='gap-x-3')
            template(v-for='(result, index) in results' :key='`carousel-${index}`')
              CmsWidgetSearchArticle(v-if='displayType === "CONTENT"' :article='result' class='w-[275px] md:w-[350px]')
              div(v-else-if='displayType === "PRODUCT"' class='w-[275px]')
                ProductListing(
                  :listing='result'
                  :is-schema-on='false'
                  v-bind='ProductListingProps'
                  class='h-full pb-1 lg:pb-0 lg:mb-5'
                )
          template(v-else)
            div(class='grid gap-6' :class='colsClasses')
              template(v-for='(result, index) in results' :key='`grid-${index}`')
                CmsWidgetSearchArticle(v-if='displayType === "CONTENT"' :article='result')
                ProductListing(
                  v-else-if='displayType === "PRODUCT"'
                  :listing='result'
                  :is-schema-on='false'
                  v-bind='ProductListingProps'
                )

            AlgoliaPagination(v-if='data.showPagination === "true"' class='mt-6' :update-route='false' @click='scrollToTop')

  div(v-else-if='$storyblok.isEditing.value' class='p-2 mb-4 text-sm text-center bg-danger-light border border-danger rounded') Error: The Search Display Widget may only be used inside of a Search Content Wrapper.
</template>

<script setup lang="ts">
const { $scrollTo, $device } = useNuxtApp()

const displayWidget = ref()
type DisplayMode = 'INHERIT' | 'GRID' | 'CAROUSEL'
interface searchDisplayData extends Widgets {
  component: 'search-display'
  noResultWidgets: []
  showPrice: boolean
  showReviews: boolean
  showShopNow: boolean
  showPagination: StringBoolean
  showSameDayShipping: boolean
  mobileDisplayMode: DisplayMode
  tabletDisplayMode: DisplayMode
  desktopDisplayMode: DisplayMode
  mobileColumnCount: string
  tabletColumnCount: string
  desktopColumnCount: string
}
const { data } = defineProps<{
  data: searchDisplayData
}>()

const editNoResults = ref(false)
const toggleEditMode = (bool: boolean) => {
  editNoResults.value = bool
}

const displayType = inject('displayType')

const ProductListingProps = computed(() => {
  return {
    showShopNow: data.showShopNow,
    showPrice: data.showPrice,
    showReviews: data.showReviews,
    showSameDayShipping: data.showSameDayShipping,
  }
})

const displayMode = computed(() => {
  const mobileMode = data.mobileDisplayMode === 'INHERIT' ? 'GRID' : data.mobileDisplayMode
  const tabletMode = data.tabletDisplayMode === 'INHERIT' ? mobileMode : data.tabletDisplayMode
  const desktopMode = data.desktopDisplayMode === 'INHERIT' ? tabletMode : data.desktopDisplayMode

  if ($device.value.isMobile) return mobileMode
  else if ($device.value.isDesktop) return desktopMode
  else return tabletMode
})

/**
 * Tailwind Grid Column Whitelist
 *
 * Because we are dynamically creating the class names to reduce the JS complexity
 * we have to list these classes out so they don't get purged during the build process
 *
 * grid-cols-1 md:grid-cols-1 lg:grid-cols-1
 * grid-cols-2 md:grid-cols-2 lg:grid-cols-2
 * grid-cols-3 md:grid-cols-3 lg:grid-cols-3
 * grid-cols-4 md:grid-cols-4 lg:grid-cols-4
 * grid-cols-5 md:grid-cols-5 lg:grid-cols-5
 * grid-cols-6 md:grid-cols-6 lg:grid-cols-6
 */
const colsClasses = computed(() => {
  const count = []
  if (data.mobileColumnCount) count.push(`grid-cols-${minMax(parseInt(data.mobileColumnCount))}`)

  if (data.tabletColumnCount) count.push(`md:grid-cols-${minMax(parseInt(data.tabletColumnCount))}`)

  if (data.desktopColumnCount) count.push(`lg:grid-cols-${minMax(parseInt(data.desktopColumnCount))}`)

  return count
})

function minMax(max: number) {
  return Math.min(Math.max(max, 1), 6)
}

function scrollToTop() {
  if (!displayWidget.value) return
  $scrollTo(displayWidget.value, 500)
}
</script>
