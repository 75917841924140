<template lang="pug">
div
  UniversalLink(:link='article.url' class='text-black hover:no-underline')
    div(v-if='article.image' class='relative')
      img(
        :key='src'
        :src='src'
        :alt='article.image.title'
        :width='imageWidth'
        :height='imageHeight'
        loading='lazy'
        class='w-full'
      )
    h2(class='text-xl font-bold leading-none xs:text-lg lg:text-xl') {{ article.title }}
    p {{ truncate(article.description) }}
</template>

<script lang="ts" setup>
const { $device, $storyblok } = useNuxtApp()

interface Article {
  description: string
  image: {
    filename: string
    title: string
  }
  url: string
}

const { article } = defineProps<{
  article: Article
}>()

const imageWidth = computed(() => ($device.value.isMobile ? 296 : 432))
const imageHeight = computed(() => ($device.value.isMobile ? 222 : 324))

const src = computed(() => {
  // Image format from storyblok
  // https://a.storyblok.com/f/148322/800x600/185e1a0f23/overlanding-thumbnail.jpg/m/296x222
  return $storyblok.formatSrc(`${article.image.filename}/m/${imageWidth.value}x${imageHeight.value}`)
})

function truncate(str: string) {
  let truncatedStr = str
  if (str.length > 160) truncatedStr = `${str.slice(0, 160).trim()}...`
  return truncatedStr
}
</script>
